import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import OrderPdfIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {ListScreen, Checkbox, Select, MtoknaOrder, Input, Packages} from '../components';
import {
    fetchMtoknaOrders,
    cleanMtoknaOrders,
    setUser,
    changeUserSetting,
    syncMtoknaOrder,
    pdfMtoknaOrder,
    pdfMtoknaOrders,
    changeMtoknaOrderState,
    sellerMtoknaOrder,
    sellerMtoknaOrders,
    changeMtoknaOrderData,
    readyMtoknaOrder,
    pickupMtoknaOrder,
    sendMtoknaOrders,
} from '../actions';
import {__, formatDate, formatAmount, getOrderButtons, toNumber} from '../functions';
import {COOKIE_SETTINGS, ORDER_SOURCES} from '../config';
import '../assets/styles/mtokna_orders.css';
import CouriersIcon from "@mui/icons-material/LocalShipping";

/**
 * Objednavky.
 */
class MtoknaOrdersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        loadingSync: false,
        lightbox: {
            multiorderpdf: false,
            state: false,
            sellers: false,
            multisellers: false,
            ready: false,
            pickup: false,
            create: false,
        },
    }};

    // Memory cache
    memoryCacheStates = null;

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Komponenta bola pripojena.
     *
     * @return {boolean}
     */
    componentDidMount() {
        const { location } = this.props;

        // Vyhladavanie podla cisla objednavky
        const searchByPassword = location.pathname
            .replace('/dashboard/', '')
            .replace('/dashboard', '')
            .replace('/orders/', '')
            .replace('/orders', '');

        if (searchByPassword !== '') {
            // Vyhladavanie podla cisla
            this.defaultFiltered = { sellers_password: searchByPassword.replace('O', '0') };
        }

        return super.componentDidMount();
    }

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch, params } = this.props;

        // Nacitame zoznam
        await fetch(this, { page, per_page, filtered, callback }, this.isAll());

        const { items } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        if (
            !_.isEmpty(this.defaultFiltered)
            && _.has(this.defaultFiltered, 'sellers_password')
            && items.total === 0
        ) {
            this.showSnackbar('error', __('Nepodarilo sa nájsť žiadnu objednávku'));
        }

        this.afterFetchList();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        return [];
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} value
     */
    onChangeCreateUserEshopId(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, create: { ...lightbox.create, user_eshop_id: value } } })
    }

    saveCreate() {
        const { lightbox } = this.state;

        this.redirect(`/manual/${lightbox.create.type}/${lightbox.create.user_eshop_id}/new`);
    }

    isAll() {
        const { params } = this.props;

        return _.has(params, 'type') && params.type === 'all';
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_orders';
    }

    showReady(id) {
        this.showLightbox('ready', { id, loading: false });
    }

    onChangeReady(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, ready: { ...lightbox.ready, [key]: value } } });
    }

    closeReady() {
        this.closeLightbox('ready');
    }

    saveReady() {
        const { lightbox } = this.state;
        const { readyMtoknaOrder } = this.props;

        this.onChangeReady('loading', true);

        readyMtoknaOrder(this, lightbox.ready.id);
    }

    showPickup(id, password = '') {
        this.showLightbox('pickup', { id, loading: false, password });
    }

    onChangePickup(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, pickup: { ...lightbox.pickup, [key]: value } } });
    }

    closePickup() {
        this.closeLightbox('pickup');
    }

    savePickup() {
        const { lightbox } = this.state;
        const { pickupMtoknaOrder } = this.props;

        this.onChangePickup('loading', true);

        pickupMtoknaOrder(this, lightbox.pickup.id, lightbox.pickup.password);
    }

    showSellers(item) {
        const settings = {
            goodeshop_poznamka_predajca: _.has(item.data.meta_data, 'goodeshop_poznamka_predajca') ? item.data.meta_data.goodeshop_poznamka_predajca : '',
            goodeshop_poznamka_eshop: _.has(item.data.meta_data, 'goodeshop_poznamka_eshop') ? item.data.meta_data.goodeshop_poznamka_eshop : '',
            goodeshop_datum_odovzdania: _.has(item.data.meta_data, 'goodeshop_datum_odovzdania') ? item.data.meta_data.goodeshop_datum_odovzdania : '',
            goodeshop_vydajka: _.has(item.data.meta_data, 'goodeshop_vydajka') ? item.data.meta_data.goodeshop_vydajka : 'Nie',
            goodeshop_zauctovana: _.has(item.data.meta_data, 'goodeshop_zauctovana') ? item.data.meta_data.goodeshop_zauctovana : 'Nie',
            goodeshop_zauctovana_vo: _.has(item.data.meta_data, 'goodeshop_zauctovana_vo') ? item.data.meta_data.goodeshop_zauctovana_vo : 'Nie',
            goodeshop_cislo_faktury: _.has(item.data.meta_data, 'goodeshop_cislo_faktury') ? item.data.meta_data.goodeshop_cislo_faktury : '',
            goodeshop_cislo_faktury_vo: _.has(item.data.meta_data, 'goodeshop_cislo_faktury_vo') ? item.data.meta_data.goodeshop_cislo_faktury_vo : '',
        };

        this.showLightbox('sellers', { id: item.id, settings, loading: false });
    }

    closeSellers() {
        this.closeLightbox('sellers');
    }

    onChangeSellersSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, sellers: { ...lightbox.sellers, settings: { ...lightbox.sellers.settings, [key]: value } } } });
    }

    onChangeMultiSellersSetting(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, multisellers: { ...lightbox.multisellers, data: { ...lightbox.multisellers.data, [key]: value } } } });
    }

    saveSellers() {
        const { lightbox } = this.state;
        const { sellerMtoknaOrder } = this.props;

        this.setState({ lightbox: { ...lightbox, sellers: { ...lightbox.sellers, loading: true } } });

        sellerMtoknaOrder(this, lightbox.sellers.id, lightbox.sellers.settings);
    }

    /**
     * Update sellers objednavok cez multiselect.
     */
    async multiSellers() {
        const { lightbox } = this.state;
        const { items, sellerMtoknaOrders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multisellers');

        // Zobrazime loading
        lightbox.multisellers.callbackLoading();

        await sellerMtoknaOrders(this, lightbox.multisellers.ids, lightbox.multisellers.data, items.filtered);

        // Zavolame callback
        lightbox.multisellers.callback();
    }

    getColumns() {
        const { user } = this.props;

        return [
            <div>
                <div>{__('Číslo')}</div>
                <div>{__('Suma')}</div>
                <div>{__('Dátum')}</div>
            </div>,
            <div>
                <div>{__('Zákaznik')}</div>
                <div>{__('E-mail')}</div>
                <div>{__('Eshop')}</div>
            </div>,
            <div>
                <div>{__('Názov predajcu')}</div>
                <div>{__('Adresa predajcu')}</div>
                <div>{__('Poznámka predajca')}</div>
            </div>,
            <div>
                <div>{__('Odovzdané')}</div>
                <div>{__('Dátum odovzdania')}</div>
                <div>{user.mtokna_type !== 'mtokna_seller' ? __('Poznámka eshop') : '-'}</div>
            </div>,
            <div>
                <div>{this.isAll() ? __('Zúčtované MO') : __('Zúčtované')}</div>
                <div>{this.isAll() ? __('Číslo faktúry MO') : __('Číslo faktúry')}</div>
                <div>-</div>
            </div>,
            this.isAll() ? <div>
                <div>{__('Zúčtované VO')}</div>
                <div>{__('Číslo faktúry VO')}</div>
                <div>-</div>
            </div> : null,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;

        const state = item._matchingData.OrderSellers.state;
        let states = _.reduce(items.states, (result, name, id) => ({ ...result, [id]: name }), {});

        if (user.mtokna_type === 'mtokna_owner') {
            states = {
                'Prijatá': 'Prijatá',
                'Nová': 'Nová',
                'Zrušená': 'Zrušená',
            };
        } else if (user.mtokna_type === 'mtokna_seller') {
            states = {
                'Pripravená na prevzatie': 'Pripravená na prevzatie',
            };
        }

        if (!_.has(states, state)) {
            states = { ...states, [state]: state };
        }

        if (user.mtokna_moderator) {
            states = { [state]: state };
        }

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons(item, {
            settings: {
                meta_data_pdf_link: '',
            },
            hasPermissionView: true,
            hasPermissionPdfOrder: true,
            hasPermissionSync: true,
            hasPermissionSend: user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator,
            userCouriers: [],
            couriers: items.couriers,
            courierSettings: {
                default_courier: 0,
            },
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            let password = '';

            if (
                !_.isEmpty(this.defaultFiltered)
                && _.has(this.defaultFiltered, 'sellers_password')
                && this.defaultFiltered.sellers_password.toString().toLowerCase() === item._matchingData.OrderSellers.password.toString().toLowerCase()
                && !this.callViewClick
            ) {
                password = item._matchingData.OrderSellers.password.toString();
            }

            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                buttons,
                callbackLoading,
                callback,
                password,
            });

            if (
                !_.isEmpty(this.defaultFiltered)
                && _.has(this.defaultFiltered, 'sellers_password')
                && this.defaultFiltered.sellers_password.toString().toLowerCase() === item._matchingData.OrderSellers.password.toString().toLowerCase()
                && !this.callViewClick
            ) {
                // Vyhladavame podla cisla objednavky
                this.callViewClick = true;

                // Zobrazime view
                buttons.view.callback(() => {}, () => {});
            }
        }

        // Nastavime callbacky
        if (typeof buttons.pdfOrder !== 'undefined') {
            buttons.pdfOrder.callback = (callbackLoading, callback) => this.pdfOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.sync !== 'undefined') {
            buttons.sync.callback = (callbackLoading, callback) => this.syncOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.send !== 'undefined') {
            buttons.send.callback = buttons.send.options.packagePayment || buttons.send.options.packageImport
                ? () => {}
                : (buttons.send.options.packageSend
                        ? () => this.onClickPackageNumber(item.package.number)
                        : () => this.showDrawer('packages', { ids: item.id })
                );
        }

        let source = '';

        if (item.data.source !== '' && _.has(ORDER_SOURCES, item.data.source)) {
            // Je vyplneny zdroj
            const sourceConfig = ORDER_SOURCES[item.data.source];

            source = <div className="source" style={{ backgroundColor: sourceConfig.background }}>
                {sourceConfig.name}
            </div>;
        }

        let accounted_key = 'goodeshop_zauctovana';
        let invoice_number_key = 'goodeshop_cislo_faktury';

        if (!this.isAll() && user.mtokna_type !== 'mtokna_seller') {
            accounted_key = 'goodeshop_zauctovana_vo';
            invoice_number_key = 'goodeshop_cislo_faktury_vo';
        }

        let seller_name = _.has(item.data.meta_data, 'spajame_custom_picked_shop_name')
            ? item.data.meta_data.spajame_custom_picked_shop_name
            : item.data.meta_data.inv_custom_picked_shop_name;

        let seller_address = _.has(item.data.meta_data, 'spajame_custom_picked_shop_address')
            ? item.data.meta_data.spajame_custom_picked_shop_address
            : item.data.meta_data.inv_custom_picked_shop_address;

        if (_.has(item._matchingData.OrderSellers, 'city')) {
            seller_address = `${seller_address} ${item._matchingData.OrderSellers.city}`;
        }

        let amount = item.data.amount;

        if (user.mtokna_type === 'mtokna_seller') {
            amount = toNumber(item.data.amount) - toNumber(item.data.delivery_type_amount) - toNumber(item.data.payment_type_amount);
        }

        let color = item.data.state.color;

        if (state === 'Na ceste k predajcovi') {
            color = 'orange';
        } else if (state === 'Doručená') {
            color = 'green';
        } else if (state === 'Zrušená') {
            color = 'grey';
        } else if (state === 'Stornovaná zákazníkom') {
            color = 'red';
        }

        return [
            <div>
                <div style={{ backgroundColor: color }} />
                <div>
                    <div>{item.data.number}</div>
                    {source}
                </div>
                <div>{formatAmount(amount, item.data.currency)}</div>
                <div>{formatDate(item.data.date)}</div>
            </div>,
            <div>
                <div>{user.mtokna_type === 'mtokna_seller'
                    ? _.truncate(_.split(item.data.client.name, ' ')[0], { length: 32 })
                    : _.truncate(item.data.client.name, { length: 32 })}</div>
                <div>{user.mtokna_type !== 'mtokna_seller'
                    ? _.truncate(item.data.client.email, { length: 32 })
                    : '-'}</div>
                <div>{_.has(items.user_eshops, item.user_eshop_id) ? items.user_eshops[item.user_eshop_id].name : '-'}</div>
            </div>,
            <div>
                <div>{_.truncate(seller_name, { length: 32 })}</div>
                <div>{_.truncate(seller_address, { length: 32 })}</div>
                <div>{_.truncate(_.has(item.data.meta_data, 'goodeshop_poznamka_predajca') && !_.isEmpty(item.data.meta_data.goodeshop_poznamka_predajca)
                    ? item.data.meta_data.goodeshop_poznamka_predajca
                    : '-', { length: 32 })}</div>
            </div>,
            <div>
                <div>{_.has(item.data.meta_data, 'goodeshop_datum_odovzdania') && !_.isEmpty(item.data.meta_data.goodeshop_datum_odovzdania)
                    ? __('Áno')
                    : __('Nie')}</div>
                <div>{_.has(item.data.meta_data, 'goodeshop_datum_odovzdania') && !_.isEmpty(item.data.meta_data.goodeshop_datum_odovzdania)
                    ? formatDate(item.data.meta_data.goodeshop_datum_odovzdania, 'dd.mm.yyyy')
                    : '-'}</div>
                <div>{_.truncate(_.has(item.data.meta_data, 'goodeshop_poznamka_eshop') && !_.isEmpty(item.data.meta_data.goodeshop_poznamka_eshop) && user.mtokna_type !== 'mtokna_seller'
                    ? item.data.meta_data.goodeshop_poznamka_eshop
                    : '-', { length: 32 })}</div>
            </div>,
            <div>
                <div>{_.has(item.data.meta_data, accounted_key) && !_.isEmpty(item.data.meta_data[accounted_key])
                    ? item.data.meta_data[accounted_key]
                    : '-'}</div>
                <div>{_.has(item.data.meta_data, invoice_number_key) && !_.isEmpty(item.data.meta_data[invoice_number_key])
                    ? item.data.meta_data[invoice_number_key]
                    : '-'}</div>
                <div>-</div>
            </div>,
            this.isAll() ? <div>
                <div>{_.has(item.data.meta_data, 'goodeshop_zauctovana_vo') && !_.isEmpty(item.data.meta_data.goodeshop_zauctovana_vo)
                    ? item.data.meta_data.goodeshop_zauctovana_vo
                    : '-'}</div>
                <div>{_.has(item.data.meta_data, 'goodeshop_cislo_faktury_vo') && !_.isEmpty(item.data.meta_data.goodeshop_cislo_faktury_vo)
                    ? item.data.meta_data.goodeshop_cislo_faktury_vo
                    : '-'}</div>
                <div>-</div>
            </div> : null,
            <div>
                {!this.isMobile() ? <div>
                    <Select
                        options={states}
                        value={state}
                        onChange={value => this.confirmState(item.id, value, '')}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {user.mtokna_type === 'mtokna_seller' ? this.renderTableButton(
                        item.id,
                        __('Tovar prevzatý zákazníkom bez výhrad'),
                        <LockOpenIcon color={state === 'Doručená' ? 'secondary' : 'inherit'} />,
                        () => this.showPickup(item.id),
                        {}
                    ) : null}
                    {user.mtokna_type !== 'mtokna_owner' && !user.mtokna_moderator ? this.renderTableButton(
                        item.id,
                        __('Pripravené na prevzatie'),
                        <AlarmOnIcon color={state === 'Pripravená na prevzatie' ? 'secondary' : 'inherit'} />,
                        () => this.showReady(item.id),
                        {}
                    ) : null}
                    {!user.mtokna_moderator ? this.renderTableButton(
                        item.id,
                        __('Predajca'),
                        <EditIcon />,
                        () => this.showSellers(item),
                        {}
                    ) : null}
                    {this.renderTableButtons(item.id, buttons, ['pdfLink', 'pdf', 'createInvoice', 'paid', 'delete'], false)}
                </div>
            </div>,
        ];
    }

    /**
     * Event po kliku na cislo zasielky.
     *
     * @param {string} number
     */
    onClickPackageNumber(number) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(number);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => item.data.number,
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        const { user } = this.props;

        return [
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multiorderpdf', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <OrderPdfIcon />,
                text: __('PDF objednávok'),
                limit: 200,
                permission: 'orders-pdf',
            },
            user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multisellers', {
                    ids,
                    callback,
                    callbackLoading,
                    data: {
                        goodeshop_poznamka_eshop: '',
                        goodeshop_zauctovana: '',
                        goodeshop_zauctovana_vo: '',
                        goodeshop_cislo_faktury: '',
                        goodeshop_cislo_faktury_vo: '',
                    }
                }),
                icon: <EditIcon />,
                text: __('Predajca'),
                limit: 50,
            } : null,
        ];
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {};
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { user, items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'sellers_send':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Odovzdané'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'sellers_invoiced':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Výdajka'),
                        value: '',
                        options: {
                            0: __('Nie'),
                            1: __('Áno'),
                        },
                        strict: false,
                    }}};

                case 'sellers_accounted':
                    if (this.isAll() || user.mtokna_type === 'mtokna_seller') {
                        return { ...result, ...{ [key]: {
                            type: 'select',
                            name: this.isAll() ? __('Zúčtované MO') : __('Zúčtované'),
                            value: '',
                            options: {
                                0: __('Nie'),
                                1: __('Áno'),
                            },
                            strict: false,
                        }}};
                    }

                    return result;

                case 'sellers_accounted_vo':
                    if (this.isAll() || user.mtokna_type !== 'mtokna_seller') {
                        return { ...result, ...{ [key]: {
                            type: 'select',
                            name: this.isAll() ? __('Zúčtované VO') : __('Zúčtované'),
                            value: '',
                            options: {
                                0: __('Nie'),
                                1: __('Áno'),
                            },
                            strict: false,
                        }}};
                    }

                    return result;

                case 'sellers_user_eshop_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Eshop'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                case 'sellers_state':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Stav'),
                        value: '',
                        options,
                        multiple: true,
                    }}};

                case 'sellers_email':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Email'),
                        value: '',
                        autocomplete: true,
                        options,
                    }}};

                case 'sellers_name':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Názov'),
                        value: '',
                        autocomplete: true,
                        options,
                    }}};

                case 'sellers_invoice':
                    if (this.isAll() || user.mtokna_type === 'mtokna_seller') {
                        return { ...result, ...{ [key]: {
                            type: 'input',
                            name: this.isAll() ? __('Faktúra MO') : __('Faktúra'),
                            value: '',
                        }}};
                    }

                    return result;

                case 'sellers_invoice_vo':
                    if (this.isAll() || user.mtokna_type !== 'mtokna_seller') {
                        return { ...result, ...{ [key]: {
                            type: 'input',
                            name: this.isAll() ? __('Faktúra VO') : __('Faktúra'),
                            value: '',
                        }}};
                    }

                    return result;

                case 'number':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Číslo objednávky'),
                        value: '',
                    }}};

                case 'sellers_password':
                    return { ...result, ...{ [key]: {
                                type: 'input',
                                name: __('Heslo'),
                                value: '',
                            }}};

                case 'sellers_city':
                    if (this.isAll()) {
                        return { ...result, ...{ [key]: {
                                    type: 'input',
                                    name: __('Mesto'),
                                    value: '',
                                }}};
                    }

                    return result;

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne objednávky');
    }

    /**
     * Event po zmene stavu objednavky.
     */
    async changeState() {
        const { lightbox } = this.state;
        const { changeMtoknaOrderState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('state');

        // Zobrazime loading
        this.loadingTableButton(this.getLoadingTableButtonId(lightbox.state.orderId, lightbox.state.viewText));

        // Zmenime stav
        await changeMtoknaOrderState(this, lightbox.state.orderId, lightbox.state.stateId);

        // Zrusime loading table button
        this.clearTableButton();
    }

    /**
     * Zobrazime lightbox na zmenu stavu.
     *
     * @param {number} orderId
     * @param {string} stateId
     * @param {string} viewText
     */
    confirmState(orderId, stateId, viewText) {
        this.showLightbox('state', { orderId, stateId, viewText });
    }

    /**
     * Stiahnutie order pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdfOrder(id, callbackLoading, callback) {
        const { pdfMtoknaOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Zavolame callback
        await pdfMtoknaOrder(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Synchronizacia objednavky.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async syncOrder(id, callbackLoading, callback) {
        const { syncMtoknaOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Synchronizacia objednavky
        await syncMtoknaOrder(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     */
    async multiOrderPdf() {
        const { lightbox } = this.state;
        const { items, pdfMtoknaOrders } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multiorderpdf');

        // Zobrazime loading
        lightbox.multiorderpdf.callbackLoading();

        await pdfMtoknaOrders(this, lightbox.multiorderpdf.ids, items.filtered);

        // Zavolame callback
        lightbox.multiorderpdf.callback();
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { items, user, changeUserSetting, changeMtoknaOrderData, sendMtoknaOrders } = this.props;
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'sellers',
                    __('Predajca'),
                    !_.isEmpty(lightbox.sellers) ? <div>{lightbox.sellers.loading ? this.renderLoading(20) :
                        <div>
                            {user.mtokna_type === 'mtokna_seller' ? <div>
                                <Input
                                    label={__('Poznámka predajca')}
                                    value={lightbox.sellers.settings.goodeshop_poznamka_predajca}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_poznamka_predajca', value)}
                                />
                            </div> : null}
                            {user.mtokna_type !== 'mtokna_seller' ? <div style={{ marginTop: 10 }}>
                                <Input
                                    label={__('Poznámka eshop')}
                                    value={lightbox.sellers.settings.goodeshop_poznamka_eshop}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_poznamka_eshop', value)}
                                />
                            </div> : null}
                            {user.mtokna_type === 'mtokna_admin' ? <div style={{ marginTop: 10 }}>
                                <Input
                                    type="date"
                                    label={__('Dátum odovzdania')}
                                    value={lightbox.sellers.settings.goodeshop_datum_odovzdania}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_datum_odovzdania', formatDate(value, 'yyyy-mm-dd'))}
                                />
                            </div> : null}
                            {user.mtokna_type === 'mtokna_admin' ? <div style={{ marginTop: 10 }}>
                                <Select
                                    label={__('Výdajka')}
                                    options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                    value={lightbox.sellers.settings.goodeshop_vydajka}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_vydajka', value)}
                                    allowEmpty={false}
                                />
                            </div> : null}
                            {user.mtokna_type === 'mtokna_admin' ? <div style={{ marginTop: 10 }}>
                                <Select
                                    label={__('Zúčtované MO')}
                                    options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                    value={lightbox.sellers.settings.goodeshop_zauctovana}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_zauctovana', value)}
                                    allowEmpty={false}
                                />
                            </div> : null}
                            {user.mtokna_type === 'mtokna_admin' ? <div style={{ marginTop: 10 }}>
                                <Input
                                    label={__('Číslo faktúry MO')}
                                    value={lightbox.sellers.settings.goodeshop_cislo_faktury}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_cislo_faktury', value)}
                                />
                            </div> : null}
                            {user.mtokna_type === 'mtokna_admin' ? <div style={{ marginTop: 10 }}>
                                <Select
                                    label={__('Zúčtované VO')}
                                    options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                    value={lightbox.sellers.settings.goodeshop_zauctovana_vo}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_zauctovana_vo', value)}
                                    allowEmpty={false}
                                />
                            </div> : null}
                            {user.mtokna_type === 'mtokna_admin' ? <div style={{ marginTop: 10 }}>
                                <Input
                                    label={__('Číslo faktúry VO')}
                                    value={lightbox.sellers.settings.goodeshop_cislo_faktury_vo}
                                    onChange={value => this.onChangeSellersSetting('goodeshop_cislo_faktury_vo', value)}
                                />
                            </div> : null}
                        </div>
                    }</div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveSellers(),
                    false,
                    () => this.closeSellers()
                )}
                {this.renderLightbox(
                    'multisellers',
                    __('Predajca'),
                    !_.isEmpty(lightbox.multisellers) ? <div>
                        <div>
                            <Input
                                label={__('Poznámka eshop')}
                                value={lightbox.multisellers.data.goodeshop_poznamka_eshop}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_poznamka_eshop', value)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Select
                                label={__('Zúčtované')}
                                options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                value={lightbox.multisellers.data.goodeshop_zauctovana}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_zauctovana', value)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Input
                                label={__('Číslo faktúry')}
                                value={lightbox.multisellers.data.goodeshop_cislo_faktury}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_cislo_faktury', value)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Select
                                label={__('Zúčtované VO')}
                                options={{ Nie: __('Nie'), 'Áno': __('Áno') }}
                                value={lightbox.multisellers.data.goodeshop_zauctovana_vo}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_zauctovana_vo', value)}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Input
                                label={__('Číslo faktúry VO')}
                                value={lightbox.multisellers.data.goodeshop_cislo_faktury_vo}
                                onChange={value => this.onChangeMultiSellersSetting('goodeshop_cislo_faktury_vo', value)}
                            />
                        </div>
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.multiSellers()
                )}
                {this.renderLightbox(
                    'create',
                    __('Nová objednávka'),
                    !_.isEmpty(lightbox.create) ? <div>
                        <Select
                            label={__('Eshop')}
                            value={lightbox.create.user_eshop_id}
                            options={lightbox.create.user_eshops}
                            onChange={value => this.onChangeCreateUserEshopId(value)}
                            allowEmpty={false}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveCreate()
                )}
                {this.renderLightbox(
                    'multiorderpdf',
                    __('Stiahnutie objednávok do pdf'),
                    !_.isEmpty(lightbox.multiorderpdf) ? <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={user.settings.merge_multi_order_pdf}
                            onChange={checked => changeUserSetting('merge_multi_order_pdf', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiOrderPdf()
                )}
                {this.renderLightbox(
                    'state',
                    __('Naozaj chcete zmeniť stav tejto objednávke?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeState()
                )}
                {this.renderLightbox(
                    'ready',
                    __('Naozaj chcete zmeniť objednávku na "Pripravené na prevzatie"?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.saveReady()
                )}
                {this.renderLightbox(
                    'pickup',
                    __('Tovar prevzatý zákazníkom bez výhrad'),
                    !_.isEmpty(lightbox.pickup) ? <div>
                        <Input
                            label={__('Heslo')}
                            value={lightbox.pickup.password}
                            onChange={value => this.onChangePickup('password', value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.savePickup()
                )}
                {this.renderDrawer('packages', {
                    title: __('Odoslanie objednávok'),
                    content: props => <Packages
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        sendOrders={sendMtoknaOrders}
                        mtokna={true}
                        {...props}
                    />,
                })}
                {this.renderDrawer('order', {
                    title: __('Objednávka'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaOrderData}
                        showPickup={(id, password) => this.showPickup(id, password)}
                        settings={items.settings}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_orders, user }) => ({ items: mtokna_orders, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaOrders,
    clean: cleanMtoknaOrders,
    setUser,
    changeUserSetting,
    syncMtoknaOrder,
    pdfMtoknaOrder,
    pdfMtoknaOrders,
    changeMtoknaOrderState,
    sellerMtoknaOrder,
    sellerMtoknaOrders,
    changeMtoknaOrderData,
    readyMtoknaOrder,
    pickupMtoknaOrder,
    sendMtoknaOrders,
})(MtoknaOrdersScreen));
