import _ from 'lodash';
import {
    FETCH_MTOKNA_MANUAL_SELLER_ORDERS,
    CLEAN_MTOKNA_MANUAL_SELLER_ORDERS,
    EDIT_MTOKNA_MANUAL_SELLER_ORDER,
    DELETE_MTOKNA_MANUAL_SELLER_ORDER,
    CREATE_MTOKNA_MANUAL_SELLER_ORDER_INVOICE,
} from './types';
import {request, __, toNumber} from '../functions';

export const fetchMtoknaManualSellerOrders = (screen, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    let uri = '/mtokna/manualSellerOrders';

    if (window.location.pathname === '/all-seller-orders') {
        uri = '/mtokna/allManualSellerOrders';
    }

    return dispatch => request(uri, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_MTOKNA_MANUAL_SELLER_ORDERS, payload: data });
    });
};

export const cleanMtoknaManualSellerOrders = () => ({ type: CLEAN_MTOKNA_MANUAL_SELLER_ORDERS });

/**
 * Zmazanie objednavky.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteMtoknaManualSellerOrder = (screen, id) => {
    return dispatch => request(`/mtokna/deleteManualSellerOrder/${id}`, { order_delete_in_eshop: false }).then(response => {
        const { status } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo zmazat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo zmazať pretože má evidovanú faktúru'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zmazaná'));

        dispatch({ type: DELETE_MTOKNA_MANUAL_SELLER_ORDER });
    });
};

/**
 * Vytvorenie faktury.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const createMtoknaManualSellerOrderInvoice = (screen, id) => {
    id = toNumber(id);

    return dispatch => request(`/mtokna/createManualSellerOrderInvoice/${id}`).then(response => {
        const { status, data } = response.data;
        let hasError = false;

        if (status === 'success') {
            hasError = _.has(data, id) && _.isString(data[id]);
        }

        if (status === 'error' || hasError) {
            // Fakturu sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Faktúru sa nepodarilo vytvoriť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!hasError) {
            screen.showSnackbar('success', __('Faktúra bola vytvorená'));
        }

        dispatch({ type: CREATE_MTOKNA_MANUAL_SELLER_ORDER_INVOICE, payload: data });
    });
};

export const changeMtoknaManualSellerOrderData = (id, data) => ({ type: EDIT_MTOKNA_MANUAL_SELLER_ORDER, payload: { id, data } });
