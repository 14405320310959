import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Button, Input, ListScreen} from '../components';
import {
    fetchMtoknaProducts,
    cleanMtoknaProducts,
    setUser,
    changeUserSetting,
    deleteMtoknaProduct,
    changeMtoknaProductStatus,
} from '../actions';
import {__, formatAmount, formatDate, isEmptyString, request} from '../functions';
import '../assets/styles/mtokna_products.css';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadIcon from "@mui/icons-material/Publish";
import {IconButton, Tooltip} from "@mui/material";

/**
 * Klienti.
 */
class MtoknaProductsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Produkty');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            delete: false,
            status: false,
            import: false,
            links: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'mtokna_products';
    }

    /**
     * Vratime zoznam stlpcov.
     *
     * @return {Array}
     */
    getColumns() {
        return [
            <div>
                <div>{__('Názov')}</div>
                <div>{__('SKU')}</div>
                <div>{__('Predajca VO')}</div>
            </div>,
            <div>
                <div>{__('Celková cena')}</div>
                <div>{__('Stav na sklade')}</div>
                <div>{__('Váha')}</div>
            </div>,
            <div>
                <div>{__('Dodávateľ')}</div>
                <div>{__('Vytvorené')}</div>
                <div>{__('Posledná zmena')}</div>
            </div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user } = this.props;

        return [
            <div>
                <div>{_.truncate(item.name, { length: 48 })}</div>
                <div>{item.sku}</div>
                <div>{item._matchingData.Users.email}</div>
            </div>,
            <div>
                <div>{formatAmount(item.data.price.replace(',', '.'), 'EUR')}</div>
                <div>{formatAmount(item.data.stock.replace(',', '.'), 'ks', 0)}</div>
                <div>{formatAmount(item.data.weight.replace(',', '.'), 'kg', 2)}</div>
            </div>,
            <div>
                <div>{item.data.brand !== '' ? item.data.brand : '-'}</div>
                <div>{formatDate(item.created, 'dd.mm.yyyy hh:ii')}</div>
                <div>{formatDate(item.modified, 'dd.mm.yyyy hh:ii')}</div>
            </div>,
            <div>
                {!user.mtokna_moderator && user.mtokna_type !== 'mtokna_owner' ? this.renderTableButton(
                    item.id,
                    item.status === 'public' ? __('Verejné') : __('Skryté'),
                    <CheckCircleIcon color={item.status === 'public' ? 'secondary' : 'inherit'} />,
                    (callbackLoading, callback) => this.confirmStatus(item.id, callbackLoading, callback, {
                        status: item.status === 'public' ? 'draft' : 'public',
                    }),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Upraviť'),
                    <EditIcon />,
                    (callbackLoading, callback) => this.redirect(`/products/view/${item.id}`),
                    {}
                ) : null}
                {!user.mtokna_moderator ? this.renderTableButton(
                    item.id,
                    __('Zmazať'),
                    <DeleteIcon />,
                    (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback),
                    {}
                ) : null}
            </div>,
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'user_id':
                    return { ...result, ...{ [key]: {
                        type: 'select',
                        name: __('Predajca VO'),
                        value: '',
                        autocomplete: true,
                        options,
                    }}};

                case 'sku':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('SKU'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    getTags() {
        const { user, items } = this.props;

        if (user.mtokna_moderator) {
            return [];
        }

        if (user.mtokna_type === 'mtokna_owner' && (items.sku_prefix === '' || items.category_name === '')) {
            return [{
                name: __('Pre aktiváciu tejto funkcie kontaktujte prosím administrátora'),
                color: 'orange',
            }];
        }

        return [
            {
                name: __('Nový produkt'),
                onClick: () => this.redirect('/products/view/new'),
            },
            user.mtokna_type === 'mtokna_owner' && !items.has_import ? {
                name: __('Import XML zo súboru'),
                onClick: () => this.showLightbox('import', { loading: false }),
                color: 'secondary',
            } : null,
            user.mtokna_type === 'mtokna_owner' && !items.has_import ? {
                name: __('Import XML z url'),
                onClick: () => this.showLightbox('links', { loading: false, items: [''] }),
                color: 'secondary',
            } : null,
            user.mtokna_type === 'mtokna_owner' && items.has_import ? {
                name: __('Prebieha import produktov'),
                color: 'orange',
            } : null,
        ];
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadny produkt');
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {Object} data
     */
    confirmStatus(id, callbackLoading, callback, data) {
        this.showLightbox('status', { id, callbackLoading, callback, data });
    }

    /**
     * Zobrazime lightbox na email.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Ulozenie emailu.
     */
    async changeStatus() {
        const { changeMtoknaProductStatus } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.status.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('status');

        // Zmenime email
        await changeMtoknaProductStatus(this, lightbox.status.id, lightbox.status.data.status);

        // Zavolame callback
        lightbox.status.callback();
    }

    /**
     * Zmazanie miesta.
     */
    async delete() {
        const { deleteMtoknaProduct, items } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme klienta
        await deleteMtoknaProduct(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    onChangeImport(type, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, import: { ...lightbox.import, [type]: value } } });
    }

    uploadFile(file) {
        if (file.type !== 'text/xml') {
            // Nie je to pdf
            this.showSnackbar('error', __('Neplatný typ súboru'));
            return;
        }

        this.onChangeImport('loading', true);

        request('/mtokna/uploadProductsImport', file, 'FILE').then(response => {
            const { status } = response.data;

            this.onChangeImport('loading', false);

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať súbor'));
                return;
            }

            // Nastavime prilohu
            window.location = '/products';
        });
    }

    onChangeLinks(type, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, links: { ...lightbox.links, [type]: value } } });
    }

    onChangeLink(key, value) {
        const { lightbox } = this.state;

        const items = _.map(lightbox.links.items, (item, k) => {
            if (k === key) {
                return value;
            }

            return item;
        });

        this.onChangeLinks('items', items);
    }

    addLink() {
        const { lightbox } = this.state;

        this.onChangeLinks('items', [ ...lightbox.links.items, '' ]);
    }

    deleteLink(key) {
        const { lightbox } = this.state;
        let { items } = lightbox.links;

        items = _.remove(items, (item, k) => {
            return k !== key;
        });

        this.onChangeLinks('items', items);
    }

    createImport() {
        const { lightbox } = this.state;

        let links = [];

        _.each(lightbox.links.items, link => {
            if (!isEmptyString(link)) {
                links = [ ...links, link ];
            }
        });

        if (_.isEmpty(links)) {
            // Nie je to pdf
            this.showSnackbar('error', __('Nezadali ste platnú url adresu'));
            return;
        }

        this.onChangeLinks('loading', true);

        request('/mtokna/createProductsImport', { links }, 'POST').then(response => {
            const { status } = response.data;

            this.onChangeLinks('loading', false);

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať súbor'));
                return;
            }

            // Nastavime prilohu
            window.location = '/products';
        });
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;

        let counter = 0;

        return (
            <div>
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento produkt?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'status',
                    __('Naozaj chcete zmeniť stav tomuto produktu?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeStatus()
                )}
                {this.renderLightbox(
                    'import',
                    __('Import xml zo súboru'),
                    !_.isEmpty(lightbox.import) ? (lightbox.import.loading ? this.renderLoading(20) : <div>
                        <div className="mtokna_products__upload">
                            <UploadIcon />
                            <div className="mtokna_products__upload__text">{__('Nahrajte súbor vo formáte xml.')}</div>
                            <input type="file" onChange={event => this.uploadFile(event.target.files[0])} />
                        </div>
                        <Button
                            onClick={() => { window.location = `https://api.goodeshop.sk/mtokna/downloadProductsXsd?token=${global.token}`; }}
                            style={{ marginRight: '20px' }}
                        >{__('Stiahnuť XSD schému')}</Button>
                        <Button
                            onClick={() => { window.location = `https://api.goodeshop.sk/mtokna/downloadProductsXml?token=${global.token}`; }}
                        >{__('Stiahnuť XML príklad')}</Button>
                    </div>) : null,
                    '',
                    __('Zrušiť'),
                    () => {}
                )}
                {this.renderLightbox(
                    'links',
                    __('Import xml z url'),
                    !_.isEmpty(lightbox.links) ? (lightbox.links.loading ? this.renderLoading(20) : <div className="mtokna_products__links">
                        {_.map(lightbox.links.items, (item, key) => {
                            counter++;

                            return (
                                <div className="mtokna_products__links__link" key={key}>
                                    <Input
                                        label={`${__('Url č.')} ${counter}`}
                                        value={item}
                                        onChange={value => this.onChangeLink(key, value)}
                                        required
                                    />
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteLink(key)}
                                            className="mtokna_products__links__link__delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        })}
                        <Button
                            onClick={() => this.addLink()}
                        >{__('Pridať')}</Button>
                    </div>) : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.createImport()
                )}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_products, user }) => ({ items: mtokna_products, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchMtoknaProducts,
    clean: cleanMtoknaProducts,
    setUser,
    changeUserSetting,
    deleteMtoknaProduct,
    changeMtoknaProductStatus,
})(MtoknaProductsScreen));
