import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Screen, Button, Input, Select, Checkbox } from '../components';
import { setUser } from '../actions';
import {__, request, formatAmount, toNumber, formatDate} from '../functions';
import '../assets/styles/order-mtokna-screen.css';
import {Autocomplete, IconButton, Table, TableBody, TableCell, TableRow, TextField, Tooltip} from "@mui/material";
import {CURRENCIES} from "../config";
import DeleteIcon from "@mui/icons-material/Delete";
import DiscountIcon from "@mui/icons-material/Discount";

/**
 * Objednavka.
 */
class OrderMtOknaScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávka');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        sameDelivery: true,
        showCompany: false,
        id: '',
        data: {},
        pickup_points: [],
        pickup_point: null,
        searched_clients: [],
        searched_clients_email: [],
        searched_products: [],
        searched_products_number: [],
        settings: {
            state_id: 'pending',
            number: '',
            currency: '',
            client: {
                name: '',
                ico: '',
                dic: '',
                ic_dph: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                zip: '',
                country: '',
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_country: '',
                delivery_phone: '',
                delivery_note: '',
                searched: {},
            },
            products: [],
            delivery_type_id: '',
            delivery_type_name: '',
            delivery_type_amount: '',
            delivery_type_pickup_point: '',
            payment_type_id: '',
            payment_type_name: '',
            payment_type_amount: '',
            meta_data: {},
            meta_data_modified: {},
            meta_data_deleted: {},
        },
        lightbox: {
            orders: false,
            discount: false,
        },
    };

    search_clients_timeout = null;
    search_clients_email_timeout = null;
    search_products_timeout = null;
    search_products_number_timeout = null;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { params, setUser } = this.props;

            const id = params.id !== 'new' ? params.id : '';
            const edit_id = _.has(params, 'edit_id') && params.edit_id !== '' ? params.edit_id : '';

            request(`/orders/createMtOkna/${id}`, { edit_id }).then(response => {
                const { status, data, sync } = response.data;

                if (status === 'error') {
                    window.location = '/orders';
                    return;
                }

                setUser(sync.user);

                const plugin = this.getPlugin(sync.user);
                const shop_id_name = plugin === 'invelity' ? 'inv_custom_picked_shop_id' : 'spajame_custom_picked_shop_id';

                let { settings, pickup_points, pickup_point, sameDelivery, showCompany } = this.state;

                let searched_clients = [];
                let searched_clients_email = [];
                let searched_products = [];
                let searched_products_number = [];

                if (id !== '') {
                    const meta_data = _.reduce(data.order.meta_data, (result, value, name) => {
                        if (_.isObject(value) && _.isArray(value)) {
                            return result;
                        }

                        return { ...result, [name]: value };
                    }, {});

                    settings = { ...settings, ...{
                        delivery_type_id: data.order.delivery_type.id.toString(),
                        delivery_type_name: data.order.delivery_type_name,
                        delivery_type_amount: data.order.delivery_type_amount.toString(),
                        delivery_type_pickup_point: data.order.client.delivery_pickup_point_id.toString(),
                        payment_type_id: data.order.payment_type.id.toString(),
                        payment_type_name: data.order.payment_type_name,
                        payment_type_amount: data.order.payment_type_amount.toString(),
                        meta_data,
                    } };

                    if (
                        this.isCustomPoints(settings.delivery_type_name)
                        && _.has(settings.meta_data, shop_id_name)
                    ) {
                        settings = {
                            ...settings,
                            delivery_type_pickup_point: settings.meta_data[shop_id_name]
                        };
                    }

                    const pickup_points_data = this.getPickupPoints(data, settings, data.order.delivery_type_name);

                    pickup_points = pickup_points_data.pickup_points;
                    pickup_point = pickup_points_data.pickup_point;
                } else {
                    settings = { ...settings, ...{
                        currency: sync.user.settings.currency,
                        client: { ...settings.client, ...{
                            country: sync.user.settings.market,
                            delivery_country: sync.user.settings.market,
                        } },
                    } };

                    if (_.has(data, 'edit_order') && !_.isEmpty(data.edit_order)) {
                        sameDelivery = false;
                        showCompany = !_.isEmpty(data.edit_order.client.ico);

                        let products = [];

                        _.each(data.edit_order.products, product => {
                            products = [ ...products, {
                                id: product.id,
                                name: product.name,
                                number: product.number,
                                quantity: product.quantity,
                                unit_price: product.unit_price,
                                tax: product.tax,
                                total_price: product.total_price,
                                centre: _.has(product.meta_data, 'centre') ? product.meta_data.centre : '',
                                seller_order_id: _.has(product.meta_data, 'seller_order_id') ? product.meta_data.seller_order_id : '',
                                discount: _.has(product.meta_data, 'discount') ? product.meta_data.discount : '',
                                autocomplete: toNumber(product.id) > 0,
                                searched: product,
                            } ];

                            searched_products = [ ...searched_products, product ];
                            searched_products_number = [ ...searched_products_number, product ];
                        });

                        let searched_client = data.edit_order.client;

                        searched_clients = [data.edit_order.client];
                        searched_clients_email = [data.edit_order.client];

                        settings = { ...settings, ...{
                            state_id: data.edit_order.state.id,
                            number: data.edit_order.number,
                            currency: data.edit_order.currency,
                            client: {
                                name: data.edit_order.client.name,
                                ico: data.edit_order.client.ico,
                                dic: data.edit_order.client.dic,
                                ic_dph: data.edit_order.client.ic_dph,
                                email: data.edit_order.client.email,
                                phone: data.edit_order.client.phone,
                                address: data.edit_order.client.address,
                                city: data.edit_order.client.city,
                                zip: data.edit_order.client.zip,
                                country: data.edit_order.client.country,
                                delivery_address: data.edit_order.client.delivery_address,
                                delivery_city: data.edit_order.client.delivery_city,
                                delivery_zip: data.edit_order.client.delivery_zip,
                                delivery_country: data.edit_order.client.delivery_country,
                                delivery_phone: data.edit_order.client.delivery_phone,
                                delivery_note: data.edit_order.client.delivery_note,
                                searched: searched_client,
                            },
                            products,
                            delivery_type_id: data.edit_order.delivery_type.id,
                            delivery_type_name: data.edit_order.delivery_type_name,
                            delivery_type_amount: data.edit_order.delivery_type.amount,
                            delivery_type_pickup_point: data.edit_order.client.delivery_pickup_point_id,
                            payment_type_id: data.edit_order.payment_type.id,
                            payment_type_name: data.edit_order.payment_type_name,
                            payment_type_amount: data.edit_order.payment_type.amount,
                            meta_data: data.edit_order.meta_data,
                        } };

                        if (
                            this.isCustomPoints(settings.delivery_type_name)
                            && _.has(settings.meta_data, shop_id_name)
                        ) {
                            settings = {
                                ...settings,
                                delivery_type_pickup_point: settings.meta_data[shop_id_name]
                            };
                        }

                        const pickup_points_data = this.getPickupPoints(data, settings, settings.delivery_type_name);

                        pickup_points = pickup_points_data.pickup_points;
                        pickup_point = pickup_points_data.pickup_point;
                    }
                }

                this.setState({
                    id,
                    data,
                    settings,
                    pickup_points,
                    pickup_point,
                    searched_clients,
                    searched_clients_email,
                    searched_products,
                    searched_products_number,
                    sameDelivery,
                    showCompany,
                });
            });
        }

        return true;
    }

    getPlugin(user) {
        if (_.has(user, 'user_eshop_id') && toNumber(user.user_eshop_id) === 39) {
            return 'invelity';
        }

        return 'spajame';
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param {Object} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings, pickup_point } = this.state;
        const { user } = this.props;

        if (settings.delivery_type_pickup_point.toString() !== prevState.settings.delivery_type_pickup_point.toString()) {
            let meta_datas = {};
            let delete_meta_datas = {};

            if (this.isPacketaPoints(settings.delivery_type_name)) {
                meta_datas = { ...meta_datas, packeta_name: pickup_point !== null ? pickup_point.name : '' };
            } else if (_.has(settings.meta_data, 'packeta_name')) {
                delete_meta_datas = { ...delete_meta_datas, packeta_name: '' };
            }

            const plugin = this.getPlugin(user);
            const shop_id_name = plugin === 'invelity' ? 'inv_custom_picked_shop_id' : 'spajame_custom_picked_shop_id';
            const shop_name_name = plugin === 'invelity' ? 'inv_custom_picked_shop_name' : 'spajame_custom_picked_shop_name';
            const shop_address_name = plugin === 'invelity' ? 'inv_custom_picked_shop_address' : 'spajame_custom_picked_shop_address';
            const shop_id_dopravy_name = plugin === 'invelity' ? 'inv_custom_parcelshop_id_dopravy' : 'spajame_custom_parcelshop_id_dopravy';
            const shop_contact_name = plugin === 'invelity' ? 'inv_custom_parcelshop_contact' : 'spajame_custom_parcelshop_contact';
            const shop_id_dopravy_name_value = plugin === 'invelity' ? 'inv_custom_parcel_shop' : 'spajame_custom_parcel_shop';

            if (this.isCustomPoints(settings.delivery_type_name)) {
                meta_datas = { ...meta_datas, ...{
                    [shop_id_name]: pickup_point !== null ? pickup_point.id : '',
                    [shop_name_name]: pickup_point !== null ? pickup_point.name : '',
                    [shop_address_name]: pickup_point !== null ? pickup_point.address : '',
                    [shop_id_dopravy_name]: shop_id_dopravy_name_value,
                    [shop_contact_name]: pickup_point !== null ? pickup_point.contact : '',
                }};
            } else if (_.has(settings.meta_data, shop_id_name)) {
                delete_meta_datas = { ...delete_meta_datas, ...{
                    [shop_id_name]: '',
                    [shop_name_name]: '',
                    [shop_address_name]: '',
                    [shop_id_dopravy_name]: '',
                    [shop_contact_name]: '',
                }};
            }

            if (this.isGlsPoints(settings.delivery_type_name)) {
                meta_datas = { ...meta_datas, ...{
                    inv_gls_picked_shop_id: pickup_point !== null ? pickup_point.id : '',
                    inv_gls_picked_shop_name: pickup_point !== null ? pickup_point.name : '',
                    inv_gls_picked_shop_address: pickup_point !== null ? pickup_point.address : '',
                    inv_gls_parcelshop_id_dopravy: 'inv_gls_parcel_shop',
                    inv_gls_picked_shop_data: '',
                }};
            } else if (_.has(settings.meta_data, 'inv_gls_picked_shop_id')) {
                delete_meta_datas = { ...delete_meta_datas, ...{
                    inv_gls_picked_shop_id: '',
                    inv_gls_picked_shop_name: '',
                    inv_gls_picked_shop_address: '',
                    inv_gls_parcelshop_id_dopravy: '',
                    inv_gls_picked_shop_data: '',
                }};
            }

            this.onChangeMetaDatas(meta_datas, delete_meta_datas);
        }
    }

    searchClients(query) {
        if (this.search_clients_timeout !== null) {
            clearTimeout(this.search_clients_timeout);
        }

        if (query.length >= 3) {
            this.search_clients_timeout = setTimeout(() => this.runSearchClients(query), 500);
        }

        this.onChangeClient('name', query);
    }

    searchClientsEmail(query) {
        if (this.search_clients_email_timeout !== null) {
            clearTimeout(this.search_clients_email_timeout);
        }

        if (query.length >= 3) {
            this.search_clients_email_timeout = setTimeout(() => this.runSearchClientsEmail(query), 500);
        }

        this.onChangeClient('email', query);
    }

    runSearchClients(query) {
        request(`/orders/searchClientsMtOkna/${query}`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_clients: status === 'success' ? data : [] });
        });
    }

    runSearchClientsEmail(query) {
        request(`/orders/searchClientsMtOkna/${query}/email`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_clients_email: status === 'success' ? data : [] });
        });
    }

    searchProducts(key, query) {
        if (this.search_products_timeout !== null) {
            clearTimeout(this.search_products_timeout);
        }

        if (query.length >= 3) {
            this.search_products_timeout = setTimeout(() => this.runSearchProducts(query), 500);
        }

        this.onChangeProduct(key, 'name', query);
    }

    searchProductsNumber(key, query) {
        if (this.search_products_number_timeout !== null) {
            clearTimeout(this.search_products_number_timeout);
        }

        if (query.length >= 3) {
            this.search_products_number_timeout = setTimeout(() => this.runSearchProductsNumber(query), 500);
        }

        this.onChangeProduct(key, 'number', query);
    }

    runSearchProducts(query) {
        request(`/orders/searchProductsMtOkna/${query}`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_products: status === 'success' ? data : [] });
        });
    }

    runSearchProductsNumber(query) {
        request(`/orders/searchProductsMtOkna/${query}/number`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_products_number: status === 'success' ? data : [] });
        });
    }

    showDiscount(key) {
        this.showLightbox('discount', { key, value: '' });
    }

    closeDiscount() {
        this.closeLightbox('discount');
    }

    saveDiscount() {
        const { lightbox } = this.state;

        this.closeDiscount();

        this.onChangeProduct(lightbox.discount.key, 'discount', lightbox.discount.value);
    }

    onChangeDiscount(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, discount: { ...lightbox.discount, value } } });
    }

    showOrders() {
        const { settings, lightbox } = this.state;

        const lightbox_data = {
            loading: true,
            items: [],
            selected: {},
            email: settings.client.email,
        };

        this.showLightbox('orders', lightbox_data);

        request(`/orders/ordersMtOkna/${settings.client.email}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.closeOrders();
                return;
            }

            this.setState({ lightbox: { ...lightbox, orders: { ...lightbox_data, items: data, loading: false } } });
        });
    }

    closeOrders() {
        this.closeLightbox('orders');
    }

    saveOrders() {
        const { lightbox, settings } = this.state;

        this.closeOrders();

        let products = [];

        _.each(lightbox.orders.items, ({ id, data }) => {
            if (!_.has(lightbox.orders.selected, id.toString())) {
                return;
            }

            const tax = 20;
            const total_price = formatAmount((toNumber(data.amount) - toNumber(data.delivery_type_amount) - toNumber(data.payment_type_amount)) * -0.2, '').replace(' ', '');
            const unit_price = formatAmount(toNumber(total_price) * (100 / (100 + tax)), '').replace(' ', '');

            const product_data = { ...this.getProduct(), ...{
                name: `Provízia za vybavenie objednávky číslo ${data.number}`,
                unit_price,
                tax: tax.toString(),
                total_price,
                seller_order_id: id,
                autocomplete: false,
            } };

            products = [ ...products, product_data ];
        });

        if (!_.isEmpty(products)) {
            this.setState({ settings: { ...settings, products: [ ...settings.products, ...products ] } });
        }
    }

    addOrder(id, data) {
        const { lightbox } = this.state;

        const selected = { ...lightbox.orders.selected, [id.toString()]: data };

        this.setState({ lightbox: { ...lightbox, orders: { ...lightbox.orders, selected } } });
    }

    removeOrder(id) {
        const { lightbox } = this.state;

        const selected = _.omit(lightbox.orders.selected, [id.toString()]);

        this.setState({ lightbox: { ...lightbox, orders: { ...lightbox.orders, selected } } });
    }

    /**
     * Event po zmene rovnakej fakturacnej a dodacej adresy.
     *
     * @param {boolean} value
     */
    onChangeSameDelivery(value) {
        const { settings } = this.state;
        let { client } = settings;

        if (value) {
            // Chceme rovnaku, nasetujeme
            client = {
                ...client,
                delivery_address: client.address,
                delivery_city: client.city,
                delivery_zip: client.zip,
                delivery_country: client.country,
                delivery_phone: client.phone,
            };
        } else {
            // Nechceme rovnaku, vycistime
            client = {
                ...client,
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_phone: '',
            };
        }

        this.setState({ settings: { ...settings, client }, sameDelivery: value });
    }

    isPacketaPoints(name) {
        return name.toLowerCase().indexOf('packet') !== -1
            && name.toLowerCase().indexOf('miest') !== -1;
    }

    isGlsPoints(name) {
        return name.toLowerCase().indexOf('gls') !== -1
            && name.toLowerCase().indexOf('miest') !== -1;
    }

    isCustomPoints(name) {
        return name.toLowerCase().indexOf('odber') !== -1
            && name.toLowerCase().indexOf('predajcov') !== -1;
    }

    getPickupPoints(data, settings, name = '') {
        if (this.isPacketaPoints(name)) {
            let pickup_point = null;

            _.each(data.pickup_points, point => {
                if (point.id.toString() === settings.delivery_type_pickup_point.toString()) {
                    pickup_point = point;
                }
            });

            return {
                pickup_points: data.pickup_points,
                pickup_point,
            };
        } else if (this.isGlsPoints(name)) {
            let pickup_point = null;

            _.each(data.pickup_points_gls, point => {
                if (point.id.toString() === settings.delivery_type_pickup_point.toString()) {
                    pickup_point = point;
                }
            });

            return {
                pickup_points: data.pickup_points_gls,
                pickup_point,
            };
        } else if (this.isCustomPoints(name)) {
            let pickup_point = null;

            _.each(data.pickup_points_custom, point => {
                if (point.id.toString() === settings.delivery_type_pickup_point.toString()) {
                    pickup_point = point;
                }
            });

            return {
                pickup_points: data.pickup_points_custom,
                pickup_point,
            };
        }

        return {
            pickup_points: [],
            pickup_point: null,
        };
    }

    /**
     * Event po zmene klienta.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeClient(type, value) {
        const { settings, sameDelivery } = this.state;

        let client = { ...settings.client, [type]: value };

        if (sameDelivery) {
            // Dodacia je rovnaka
            switch (type) {
                case 'address':
                    // Menime adresu
                    client = { ...client, delivery_address: value };
                    break;

                case 'city':
                    // Menime mesto
                    client = { ...client, delivery_city: value };
                    break;

                case 'zip':
                    // Menime PSC
                    client = { ...client, delivery_zip: value };
                    break;

                case 'country':
                    // Menime krajinu
                    client = { ...client, delivery_country: value };
                    break;

                case 'phone':
                    // Menime telefon
                    client = { ...client, delivery_phone: value };
                    break;

                default:
                    break;
            }
        }

        this.setState({ settings: { ...settings, client } });
    }

    /**
     * Event po zmene klienta.
     *
     * @param {number} key
     * @param {string} type
     * @param {string} value
     */
    onChangeProduct(key, type, value) {
        const { settings } = this.state;

        const products = _.map(settings.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                if (type === 'name') {
                    return { ...product, name: value };
                } else if (type === 'centre') {
                    return { ...product, centre: value };
                } else if (type === 'number') {
                    return { ...product, number: value };
                }

                let discount = product.discount;
                let quantity = product.quantity;
                let unit_price = product.unit_price;
                let tax = product.tax;
                let total_price = product.total_price;

                value = value.replace(/[^0-9.,-]/g, '');

                switch (type) {
                    default:
                    case 'quantity':
                        // Menime pocet
                        quantity = value;
                        break;

                    case 'unit_price':
                        // Menime jednotkovu cenu
                        unit_price = value;
                        total_price = formatAmount(toNumber(unit_price) * (toNumber(tax) / 100 + 1), '').replace(' ', '');
                        break;

                    case 'tax':
                        // Menime DPH
                        tax = value;
                        total_price = formatAmount(toNumber(unit_price) * (toNumber(tax) / 100 + 1), '').replace(' ', '');
                        break;

                    case 'total_price':
                        // Menime celkovu cenu
                        total_price = value;
                        unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                        break;

                    case 'discount':
                        discount = value;

                        const discounted = toNumber(total_price) - (toNumber(total_price) * (discount / 100));
                        total_price = formatAmount(discounted, '').replace(' ', '');
                        unit_price = formatAmount(toNumber(discounted) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                        break;
                }

                product = { ...product, quantity, unit_price, tax, total_price, discount };
            }

            return product;
        });

        this.setState({ settings: { ...settings, products }});
    }

    /**
     * Pridanie produktu.
     *
     * @param {object} data
     */
    addProduct(data = {}) {
        const { settings } = this.state;

        const product_data = { ...this.getProduct(), ...data };

        this.setState({ settings: { ...settings, products: [ ...settings.products, product_data ] } });
    }

    /**
     * Zmazanie produktu.
     *
     * @param {number} key
     */
    deleteProduct(key) {
        const { settings } = this.state;
        let { products } = settings;

        products = _.remove(products, (item, k) => {
            return k !== key;
        });

        this.setState({ settings: { ...settings, products }});
    }

    /**
     * Vratime data noveho produktu.
     *
     * @return {Object}
     */
    getProduct() {
        return {
            id: '',
            name: '',
            number: '',
            quantity: 1,
            unit_price: '',
            tax: '0',
            total_price: '',
            centre: '',
            seller_order_id: '',
            discount: '',
            autocomplete: true,
        };
    }

    /**
     * Event po vybrani produktu.
     *
     * @param {number} key
     * @param {Object} product_data
     */
    onSelectProduct(key, product_data) {
        const { settings } = this.state;

        const products = _.map(settings.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                product = {
                    ...product,
                    id: product_data !== null ? product_data.id : '',
                    name: product_data !== null ? product_data.name : '',
                    number: product_data !== null ? product_data.number : '',
                    unit_price: product_data !== null ? formatAmount(toNumber(product_data.unit_price), '', 2).replace(' ', '') : '',
                    total_price: product_data !== null ? formatAmount(toNumber(product_data.total_price), '', 2).replace(' ', '') : '',
                    tax: product_data !== null ? formatAmount(toNumber(product_data.tax), '', 0).replace(' ', ''): '',
                };
            }

            return product;
        });

        this.setState({ settings: { ...settings, products }});
    }

    /**
     * Event po vybrani klienta.
     *
     * @param {Object} client_data
     */
    onSelectClient(client_data) {
        const { settings, sameDelivery, showCompany } = this.state;

        this.setState({ settings: { ...settings, client: {
            id: client_data !== null ? client_data.id : '',
            name: client_data !== null ? client_data.name : '',
            ico: client_data !== null ? client_data.ico : '',
            dic: client_data !== null ? client_data.dic : '',
            ic_dph: client_data !== null ? client_data.ic_dph : '',
            email: client_data !== null ? client_data.email : '',
            phone: client_data !== null ? client_data.phone : '',
            address: client_data !== null ? client_data.address : '',
            city: client_data !== null ? client_data.city : '',
            zip: client_data !== null ? client_data.zip : '',
            country: client_data !== null ? client_data.country : '',
            delivery_address: client_data !== null ? client_data.delivery_address : '',
            delivery_city: client_data !== null ? client_data.delivery_city : '',
            delivery_zip: client_data !== null ? client_data.delivery_zip : '',
            delivery_country: client_data !== null ? client_data.delivery_country : '',
            delivery_phone: client_data !== null ? client_data.delivery_phone : '',
            delivery_note: client_data !== null ? client_data.delivery_note : '',
        } },
            sameDelivery: client_data !== null ? false : sameDelivery,
            showCompany: client_data !== null && client_data.ico !== '' ? true : showCompany,
        });
    }

    onChangeSetting(name, value) {
        const { data, settings } = this.state;

        let additional = {};
        let additional_state = {};

        switch (name) {
            case 'delivery_type_id':
                additional_state = {
                    pickup_points: [],
                    pickup_point: null,
                };

                _.map(data.eshop_data.delivers, ({ id, name, amount }) => {
                    if (id.toString() === value.toString()) {
                        additional = {
                            delivery_type_name: name,
                            delivery_type_amount: amount.toString(),
                            delivery_type_pickup_point: '',
                        };

                        additional_state = this.getPickupPoints(data, { ...settings, delivery_type_pickup_point: '' }, name);
                    }
                });
                break;

            case 'payment_type_id':
                _.map(data.eshop_data.payments, ({ id, name, amount }) => {
                    if (id.toString() === value.toString()) {
                        additional = {
                            payment_type_name: name,
                            payment_type_amount: amount.toString(),
                        };
                    }
                });
                break;
        }

        this.setState({ settings: { ...settings, [name]: value, ...additional }, ...additional_state });
    }

    onChangeMetaData(name, value) {
        let { settings, meta_data_deleted } = this.state;

        if (_.has(meta_data_deleted, name)) {
            meta_data_deleted = _.omit(meta_data_deleted, [name]);
        }

        this.setState({ settings: {
            ...settings,
            meta_data: { ...settings.meta_data, [name]: value },
            meta_data_modified: { ...settings.meta_data_modified, [name]: value },
            meta_data_deleted
        }});
    }

    onChangeMetaDatas(datas, deleted_datas = null) {
        let { settings, meta_data_deleted } = this.state;

        if (deleted_datas !== null) {
            meta_data_deleted = deleted_datas;
        }

        _.each(_.keys(datas), key => {
            if (_.has(meta_data_deleted, key)) {
                meta_data_deleted = _.omit(meta_data_deleted, [key]);
            }
        });

        this.setState({ settings: {
            ...settings,
            meta_data: { ...settings.meta_data, ...datas },
            meta_data_modified: { ...settings.meta_data_modified, ...datas },
            meta_data_deleted
        }});
    }

    save() {
        const { params } = this.props;
        let { data, settings } = this.state;

        const id = params.id !== 'new' ? params.id : '';
        const edit_id = _.has(params, 'edit_id') && params.edit_id !== '' ? params.edit_id : '';

        if (id !== '') {
            // Edit
            let changed = {};

            if (settings.delivery_type_id.toString() !== data.order.delivery_type.id.toString()) {
                changed = { ...changed, delivery_type_id: settings.delivery_type_id };
            }

            if (settings.delivery_type_amount.toString() !== data.order.delivery_type_amount.toString()) {
                changed = { ...changed, delivery_type_amount: settings.delivery_type_amount };
            }

            if (settings.payment_type_id.toString() !== data.order.payment_type.id.toString()) {
                changed = { ...changed, payment_type_id: settings.payment_type_id };
            }

            if (settings.payment_type_amount.toString() !== data.order.payment_type_amount.toString()) {
                changed = { ...changed, payment_type_amount: settings.payment_type_amount };
            }

            if (!_.isEmpty(settings.meta_data_modified)) {
                changed = { ...changed, meta_data_modified: _.reduce(settings.meta_data_modified, (result, value, key) => {
                    if (_.has(settings.meta_data_deleted, key)) {
                        return result;
                    }

                    return { ...result, [key]: value };
                }, {}) };
            }

            if (!_.isEmpty(settings.meta_data_deleted)) {
                changed = { ...changed, meta_data_deleted: settings.meta_data_deleted };
            }

            if (!_.isEmpty(changed)) {
                this.setState({ loading: true });

                request(`/orders/editMtOkna/${id}`, { data: changed }, 'POST').then(response => {
                    const { status } = response.data;

                    this.setState({ loading: false });

                    if (status === 'error') {
                        this.showSnackbar('error', __('Nepodarilo sa uložiť objednávku'));
                        return;
                    }

                    this.showSnackbar('success', __('Objednávka bola uložená'));

                    setTimeout(() => { window.location = '/orders?mtokna=1' }, 500);
                });
            }

            return;
        }

        this.setState({ loading: true });

        settings = { ...settings, id: edit_id };

        request('/orders/addMtOkna', { data: settings }, 'POST').then(response => {
            const { status } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa uložiť objednávku'));
                return;
            }

            this.showSnackbar('success', __('Objednávka bola uložená'));

            setTimeout(() => { window.location = '/orders' }, 500);
        });
    }

    onChangePickupPoint(pickup_point) {
        const { settings } = this.state;

        if (pickup_point === null) {
            this.setState({
                pickup_point,
                settings: { ...settings, delivery_type_pickup_point: '' },
            });
            return;
        }

        this.setState({
            pickup_point: pickup_point,
            settings: { ...settings, delivery_type_pickup_point: pickup_point.id },
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const {
            loading,
            data,
            id,
            settings,
            pickup_points,
            pickup_point,
            sameDelivery,
            showCompany,
            lightbox,
            searched_clients,
            searched_products,
            searched_clients_email,
            searched_products_number,
        } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        const isEdit = id !== '';

        // Vytiahneme zoznam stavov
        const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam platieb
        let paymentTypes = _.reduce(data.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        if (
            settings.delivery_type_id.toString() === '28'
            && _.has(paymentTypes, 'bacs')
            && (
                settings.payment_type_id.toString() === ''
                || settings.payment_type_id.toString() === 'bacs'
            )
        ) {
            paymentTypes = { bacs: paymentTypes.bacs };
        }

        // Vytiahneme zoznam dodani
        const deliveryTypes = _.reduce(data.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        const tax = 20;
        let unitPrice = toNumber(settings.delivery_type_amount) * (100 / (100 + tax)) + toNumber(settings.payment_type_amount) * (100 / (100 + tax));
        let totalPrice = toNumber(settings.delivery_type_amount) + toNumber(settings.payment_type_amount);

        return (
            <div className="order-mtokna">
                <div className="order-mtokna__header">
                    <div className="order-mtokna__header__left">
                        <div className="order-mtokna__header__left__title">
                            {isEdit ? `${__('Objednávka č.')} ${data.order.number}` : __('Nová objednávka')}
                        </div>
                    </div>
                </div>
                <div className="order-mtokna__content">
                    <div className="order-mtokna__content__sections">
                        <div className="order-mtokna__content__sections__section">
                            {!isEdit ? <Input
                                label={__('Číslo objednávky')}
                                value={settings.number}
                                onChange={value => this.onChangeSetting('number', value)}
                            /> : null}
                            {!isEdit ? <Select
                                label={__('Stav')}
                                value={settings.state_id}
                                options={states}
                                onChange={value => this.onChangeSetting('state_id', value)}
                                allowEmpty={false}
                            /> : null}
                            {!isEdit ? <Select
                                label={__('Mena')}
                                value={settings.currency}
                                options={CURRENCIES}
                                onChange={value => this.onChangeSetting('currency', value)}
                                allowEmpty={false}
                            /> : null}
                            <Select
                                label={__('Dodanie')}
                                value={settings.delivery_type_id}
                                options={deliveryTypes}
                                onChange={value => this.onChangeSetting('delivery_type_id', value)}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Dodanie cena')}
                                value={settings.delivery_type_amount}
                                onChange={value => this.onChangeSetting('delivery_type_amount', value)}
                            />
                            {!_.isEmpty(pickup_points) ? <Input
                                label={__('Odberné miesto')}
                                content={<Autocomplete
                                    options={pickup_points}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => this.onChangePickupPoint(value !== null ? value : null)}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať názov...')}
                                        variant="outlined"
                                    />}
                                    noOptionsText={__('Nenašlo sa žiadne odberné miesto')}
                                    clearText={__('Zrušiť')}
                                    value={pickup_point}
                                />}
                            /> : null}
                            <div className="order-mtokna__content__sections__section__delimiter" />
                            <Select
                                label={__('Platba')}
                                value={settings.payment_type_id}
                                options={paymentTypes}
                                onChange={value => this.onChangeSetting('payment_type_id', value)}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Platba cena')}
                                value={settings.payment_type_amount}
                                onChange={value => this.onChangeSetting('payment_type_amount', value)}
                            />
                        </div>
                        {!isEdit ? <div className="order-mtokna__content__sections__section">
                            <Input
                                label={__('Meno a priezvisko (názov firmy)')}
                                content={<Autocomplete
                                    options={searched_clients}
                                    getOptionLabel={option => `${option.name} (${option.email})`}
                                    onChange={(event, value) => this.onSelectClient(value)}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať meno...')}
                                        variant="outlined"
                                        onChange={event => this.searchClients(event.target.value)}
                                    />}
                                    defaultValue={!_.isEmpty(settings.client.searched) ? settings.client.searched : null}
                                    noOptionsText={__('Nenašiel sa žiadny zákazník')}
                                    clearText={__('Zrušiť')}
                                    inputValue={settings.client.name}
                                    freeSolo
                                />}
                            />
                            <Input
                                label={__('Email')}
                                content={<Autocomplete
                                    options={searched_clients_email}
                                    getOptionLabel={option => option.email}
                                    onChange={(event, value) => this.onSelectClient(value)}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať email...')}
                                        variant="outlined"
                                        onChange={event => this.searchClientsEmail(event.target.value)}
                                    />}
                                    defaultValue={!_.isEmpty(settings.client.searched) ? settings.client.searched : null}
                                    noOptionsText={__('Nenašiel sa žiadny zákazník')}
                                    clearText={__('Zrušiť')}
                                    inputValue={settings.client.email}
                                    freeSolo
                                />}
                            />
                            {!showCompany ? <div style={{ marginBottom: '10px' }}><Checkbox
                                label={__('Firma')}
                                value={showCompany}
                                onChange={checked => this.setState({ showCompany: checked })}
                            /></div> : null}
                            {showCompany ? <div>
                                <Input
                                    label={__('IČO')}
                                    value={settings.client.ico}
                                    onChange={value => this.onChangeClient('ico', value)}
                                />
                                <Input
                                    label={__('DIČ')}
                                    value={settings.client.dic}
                                    onChange={value => this.onChangeClient('dic', value)}
                                />
                                <Input
                                    label={__('IČ DPH')}
                                    value={settings.client.ic_dph}
                                    onChange={value => this.onChangeClient('ic_dph', value)}
                                />
                            </div> : null}
                            <Input
                                label={__('Adresa (fakturácia)')}
                                value={settings.client.address}
                                onChange={value => this.onChangeClient('address', value)}
                            />
                            <Input
                                label={__('Mesto (fakturácia)')}
                                value={settings.client.city}
                                onChange={value => this.onChangeClient('city', value)}
                            />
                            <Input
                                label={__('PSČ (fakturácia)')}
                                value={settings.client.zip}
                                onChange={value => this.onChangeClient('zip', value)}
                            />
                            <Select
                                label={__('Krajina (fakturácia)')}
                                value={settings.client.country}
                                options={data.countries}
                                onChange={value => this.onChangeClient('country', value)}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Telefón (fakturácia)')}
                                value={settings.client.phone}
                                onChange={value => this.onChangeClient('phone', value)}
                            />
                            <Checkbox
                                label={__('Dodacia adresa rovnaká ako fakturačná')}
                                value={sameDelivery}
                                onChange={checked => this.onChangeSameDelivery(checked)}
                            />
                        </div> : <div className="order-mtokna__content__sections__section" />}
                        <div className="order-mtokna__content__sections__section">
                            {!isEdit ? <Input
                                label={__('Adresa (dodanie)')}
                                value={settings.client.delivery_address}
                                onChange={value => this.onChangeClient('delivery_address', value)}
                                disabled={sameDelivery}
                            /> : null}
                            {!isEdit ? <Input
                                label={__('Mesto (dodanie)')}
                                value={settings.client.delivery_city}
                                onChange={value => this.onChangeClient('delivery_city', value)}
                                disabled={sameDelivery}
                            /> : null}
                            {!isEdit ? <Input
                                label={__('PSČ (dodanie)')}
                                value={settings.client.delivery_zip}
                                onChange={value => this.onChangeClient('delivery_zip', value)}
                                disabled={sameDelivery}
                            /> : null}
                            {!isEdit ? <Select
                                label={__('Krajina (dodanie)')}
                                value={settings.client.delivery_country}
                                options={data.countries}
                                onChange={value => this.onChangeClient('delivery_country', value)}
                                disabled={sameDelivery}
                                allowEmpty={false}
                            /> : null}
                            {!isEdit ? <Input
                                label={__('Telefón (dodanie)')}
                                value={settings.client.delivery_phone}
                                onChange={value => this.onChangeClient('delivery_phone', value)}
                                disabled={sameDelivery}
                            /> : null}
                            {!isEdit ? <Input
                                label={__('Poznámka (dodanie)')}
                                value={settings.client.delivery_note}
                                onChange={value => this.onChangeClient('delivery_note', value)}
                            /> : null}
                            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>{__('Meta dáta')}</div>
                            {_.map(settings.meta_data, (value, key) => {
                                if (_.has(settings.meta_data_deleted, key)) {
                                    return null;
                                }

                                return <Input
                                    label={key}
                                    value={value}
                                    onChange={value => this.onChangeMetaData(key, value)}
                                    key={key}
                                />;
                            })}
                        </div>
                    </div>
                    {!isEdit ? <div className="order-mtokna__content__products">
                        <div className="order-mtokna__content__products__title">{__('Produkty')}</div>
                        {_.map(settings.products, (product, key) => {
                            const quantity = toNumber(product.quantity);
                            unitPrice += toNumber(product.unit_price) * quantity;
                            totalPrice += toNumber(product.total_price) * quantity;

                            return (
                                <div className="order-mtokna__content__products__product" key={key}>
                                    {product.autocomplete ? <Input
                                        label={__('SKU')}
                                        content={<Autocomplete
                                            options={searched_products_number}
                                            getOptionLabel={option => option.number}
                                            onChange={(event, value) => this.onSelectProduct(key, value)}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('SKU')}
                                                variant="outlined"
                                                onChange={event => this.searchProductsNumber(key, event.target.value)}
                                                defaultValue={product.number}
                                            />}
                                            defaultValue={!_.isEmpty(product.searched) ? product.searched : null}
                                            noOptionsText={__('Nenašiel sa žiadny produkt')}
                                            clearText={__('Zrušiť')}
                                            inputValue={product.number}
                                            freeSolo
                                        />}
                                    /> : <Input
                                        label={__('SKU')}
                                        value={product.number}
                                        onChange={value => this.onChangeProduct(key, 'number', value)}
                                    />}
                                    {product.autocomplete ? <Input
                                        label={__('Názov')}
                                        content={<Autocomplete
                                            options={searched_products}
                                            getOptionLabel={option => `${option.name} / ${option.number}`}
                                            onChange={(event, value) => this.onSelectProduct(key, value)}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('Začnite písať názov...')}
                                                variant="outlined"
                                                onChange={event => this.searchProducts(key, event.target.value)}
                                                defaultValue={product.name}
                                            />}
                                            defaultValue={!_.isEmpty(product.searched) ? product.searched : null}
                                            noOptionsText={__('Nenašiel sa žiadny produkt')}
                                            clearText={__('Zrušiť')}
                                            inputValue={product.name}
                                            freeSolo
                                        />}
                                    /> : <Input
                                        label={__('Názov')}
                                        value={product.name}
                                        onChange={value => this.onChangeProduct(key, 'name', value)}
                                    />}
                                    <Input
                                        label={__('Počet')}
                                        value={product.quantity}
                                        onChange={value => this.onChangeProduct(key, 'quantity', value)}
                                    />
                                    <Input
                                        label={__('Cena bez DPH')}
                                        value={product.unit_price}
                                        onChange={value => this.onChangeProduct(key, 'unit_price', value)}
                                    />
                                    <Input
                                        label={__('DPH')}
                                        value={product.tax}
                                        onChange={value => this.onChangeProduct(key, 'tax', value)}
                                    />
                                    <Input
                                        label={__('Cena s DPH')}
                                        value={product.total_price}
                                        onChange={value => this.onChangeProduct(key, 'total_price', value)}
                                    />
                                    <Input
                                        label={__('Zľava')}
                                        value={product.discount}
                                        disabled
                                    />
                                    <Input
                                        label={__('Stredisko')}
                                        value={product.centre}
                                        onChange={value => this.onChangeProduct(key, 'centre', value)}
                                    />
                                    <Tooltip title={__('Pridať zľavu')}>
                                        <span><IconButton
                                            onClick={() => this.showDiscount(key)}
                                            className="order-mtokna__content__products__product__discount"
                                        >
                                            <DiscountIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="order-mtokna__content__products__product__delete"
                                            disabled={key === 0}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        })}
                        <Button
                            onClick={() => this.addProduct()}
                            className="order-mtokna__content__products__button"
                        >{__('Pridať produkt')}</Button>
                        <Button
                            onClick={() => this.showOrders()}
                            className="order-mtokna__content__products__button"
                            disabled={settings.client.email === ''}
                        >{__('Pridať províziu')}</Button>
                    </div> : null}
                    {!isEdit ? <div className="order-mtokna__content__summary">
                        <div className="order-mtokna__content__summary__line">
                            <div className="order-mtokna__content__summary__line__name">{__('Bez DPH')}</div>
                            <div className="order-mtokna__content__summary__line__value">{formatAmount(unitPrice, settings.currency)}</div>
                        </div>
                        <div className="order-mtokna__content__summary__line">
                            <div className="order-mtokna__content__summary__line__name">{__('Celkom')}</div>
                            <div className="order-mtokna__content__summary__line__value">{formatAmount(totalPrice, settings.currency)}</div>
                        </div>
                    </div> : null}
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="order-mtokna__content__button"
                        color="green"
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'orders',
                    !_.isEmpty(lightbox.orders) ? `${__('Predajca')} - ${lightbox.orders.email}` : '',
                    !_.isEmpty(lightbox.orders) ? <div>{lightbox.orders.loading ? this.renderLoading(20) :
                        <div>
                            <Table size="small">
                                <TableBody>
                                    {_.map(lightbox.orders.items, (item, key) => {
                                        const selected = _.has(lightbox.orders.selected, item.id.toString());

                                        return (
                                            <TableRow key={key}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        value={selected}
                                                        onChange={checked => selected
                                                            ? this.removeOrder(item.id)
                                                            : this.addOrder(item.id, item.data)}
                                                    />
                                                </TableCell>
                                                <TableCell>{formatDate(item.data.date)}</TableCell>
                                                <TableCell><span style={{ fontWeight: 'bold', color: '#5c70ff' }}>{item.data.number}</span></TableCell>
                                                <TableCell>{formatAmount(item.data.amount, item.data.currency)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    }</div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveOrders(),
                    false,
                    () => this.closeOrders()
                )}
                {this.renderLightbox(
                    'discount',
                    __('Zľava'),
                    !_.isEmpty(lightbox.discount) ? <div>
                        <Input
                            label={__('Zľava')}
                            value={lightbox.discount.value}
                            onChange={value => this.onChangeDiscount(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveDiscount(),
                    false,
                    () => this.closeDiscount()
                )}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, {
    setUser,
})(OrderMtOknaScreen));
