import UserReducer from './UserReducer';
import DashboardReducer from './DashboardReducer';
import OrdersReducer from './OrdersReducer';
import ClientsReducer from './ClientsReducer';
import ProductsReducer from './ProductsReducer';
import InvoicesReducer from './InvoicesReducer';
import InvoicePaymentsReducer from './InvoicePaymentsReducer';
import LabelsReducer from './LabelsReducer';
import ExportsReducer from './ExportsReducer';
import TemplatesReducer from './TemplatesReducer';
import TemplateReducer from './TemplateReducer';
import ExportReducer from './ExportReducer';
import BmailsReducer from './BmailsReducer';
import AddonsReducer from './AddonsReducer';
import EshopReducer from './EshopReducer';
import AccountingReducer from './AccountingReducer';
import StorageReducer from './StorageReducer';
import EmployeesReducer from './EmployeesReducer';
import EmployeeReducer from './EmployeeReducer';
import NotificationsReducer from './NotificationsReducer';
import SettingsReducer from './SettingsReducer';
import MarketplacesReducer from './MarketplacesReducer';
import AdminReducer from './AdminReducer';
import MenuReducer from './MenuReducer';
import MarketplaceProductsReducer from './MarketplaceProductsReducer';
import OrderReducer from './OrderReducer';
import TrackTraceReducer from './TrackTraceReducer';
import ComplaintsReducer from './ComplaintsReducer';
import ComplaintReducer from './ComplaintReducer';
import MtoknaSellersReducer from './MtoknaSellersReducer';
import MtoknaOwnersReducer from './MtoknaOwnersReducer';
import MtoknaPointsReducer from './MtoknaPointsReducer';
import MtoknaOrdersReducer from './MtoknaOrdersReducer';
import MtoknaManualOrdersReducer from "./MtoknaManualOrdersReducer";
import MtoknaTemplatesReducer from "./MtoknaTemplatesReducer";
import MtoknaTemplateReducer from "./MtoknaTemplateReducer";
import MtoknaOwnerSmtpReducer from "./MtoknaOwnerSmtpReducer";
import MtoknaEshopReducer from "./MtoknaEshopReducer";
import AderynProductsReducer from "./AderynProductsReducer";
import AderynProductLogsReducer from "./AderynProductLogsReducer";
import MtoknaFilesReducer from "./MtoknaFilesReducer";
import CabProductsReducer from "./CabProductsReducer";
import MtoknaManualSellerOrdersReducer from "./MtoknaManualSellerOrdersReducer";
import MtoknaBannersReducer from "./MtoknaBannersReducer";
import MtoknaModeratorsReducer from "./MtoknaModeratorsReducer";
import MtoknaManualCustomerOrdersReducer from "./MtoknaManualCustomerOrdersReducer";
import MtoknaComplaintsReducer from "./MtoknaComplaintsReducer";
import MtoknaCategoriesReducer from "./MtoknaCategoriesReducer";
import MtoknaProductsReducer from "./MtoknaProductsReducer";
import MtoknaSchemesReducer from "./MtoknaSchemesReducer";

// Zoznam reducerov
export const reducers = {
    user: UserReducer,
    dashboard: DashboardReducer,
    orders: OrdersReducer,
    clients: ClientsReducer,
    products: ProductsReducer,
    invoices: InvoicesReducer,
    invoice_payments: InvoicePaymentsReducer,
    labels: LabelsReducer,
    exports: ExportsReducer,
    templates: TemplatesReducer,
    template: TemplateReducer,
    export_data: ExportReducer,
    bmails: BmailsReducer,
    addons: AddonsReducer,
    eshop: EshopReducer,
    accounting: AccountingReducer,
    storage: StorageReducer,
    employees: EmployeesReducer,
    employee: EmployeeReducer,
    notifications: NotificationsReducer,
    settings: SettingsReducer,
    marketplaces: MarketplacesReducer,
    admin: AdminReducer,
    menu: MenuReducer,
    marketplaceProducts: MarketplaceProductsReducer,
    order: OrderReducer,
    track_trace: TrackTraceReducer,
    complaints: ComplaintsReducer,
    complaint: ComplaintReducer,
    mtokna_sellers: MtoknaSellersReducer,
    mtokna_owners: MtoknaOwnersReducer,
    mtokna_points: MtoknaPointsReducer,
    mtokna_orders: MtoknaOrdersReducer,
    mtokna_manual_orders: MtoknaManualOrdersReducer,
    mtokna_templates: MtoknaTemplatesReducer,
    mtokna_template: MtoknaTemplateReducer,
    mtokna_owner_smtp: MtoknaOwnerSmtpReducer,
    mtokna_eshop: MtoknaEshopReducer,
    aderyn_products: AderynProductsReducer,
    aderyn_product_logs: AderynProductLogsReducer,
    mtokna_files: MtoknaFilesReducer,
    cab_products: CabProductsReducer,
    mtokna_manual_seller_orders: MtoknaManualSellerOrdersReducer,
    mtokna_banners: MtoknaBannersReducer,
    mtokna_moderators: MtoknaModeratorsReducer,
    mtokna_manual_customer_orders: MtoknaManualCustomerOrdersReducer,
    mtokna_complaints: MtoknaComplaintsReducer,
    mtokna_categories: MtoknaCategoriesReducer,
    mtokna_products: MtoknaProductsReducer,
    mtokna_schemes: MtoknaSchemesReducer,
};
