import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-build-full';
import { Tooltip, Chip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { Screen, Message, Input, Button } from '../components';
import { fetchMtoknaTemplate, setUser, createMtoknaTemplate, cleanMtoknaTemplate } from '../actions';
import { __, toNumber, isEmptyString } from '../functions';
import { PLACEHOLDERS, ADDITIONAL_PLACEHOLDERS, EMAIL_PLACEHOLDERS } from '../config';
import '../assets/styles/mtokna_template.css';

/**
 * Email.
 */
class MtoknaTemplateScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Nový email');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        loading: false,
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { fetchMtoknaTemplate } = this.props;

            // Vytiahneme id emailu
            const templateId = this.getTemplateId();

            // Nasetujeme title
            this.setTitle(templateId > 0 ? __('Úprava emailu') : __('Nový email'));

            // Nacitame email
            await fetchMtoknaTemplate(this, templateId);

            const { template } = this.props;

            let data = {
                name: '',
                subject: '',
                text: '',
            };

            if (!_.isEmpty(template.template)) {
                // Mame data z editu
                data = {
                    name: template.template.name,
                    subject: template.template.subject,
                    text: template.template.text,
                };
            }

            this.setState({ data });
        }

        return true;
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        const { cleanMtoknaTemplate } = this.props;

        cleanMtoknaTemplate();
    }

    /**
     * Vratime id sablony.
     *
     * @return {number}
     */
    getTemplateId() {
        const { location } = this.props;

        return toNumber(location.pathname.replace('/template/', ''));
    }

    /**
     * Ulozenie.
     */
    save() {
        let { data } = this.state;

        let error = '';

        if (isEmptyString(data.name)) {
            // Nie je vyplneny nazov
            error = __('Nie je vyplnený názov');
        } else if (isEmptyString(data.subject)) {
            // Nie je vyplneny predmet
            error = __('Nie je vyplnený predmet');
        } else if (isEmptyString(data.text)) {
            // Nie je vyplneny text
            error = __('Nie je vyplnená šablóna');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        const { createMtoknaTemplate } = this.props;

        // Ulozime sablonu
        createMtoknaTemplate(this, { ...data, id: this.getTemplateId() });
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string} value
     */
    onChange(type, value) {
        const { data } = this.state;

        let additional = {};

        this.setState({ data: { ...data, [type]: value, ...additional } });
    }

    /**
     * Event po kliku na placeholder.
     *
     * @param {string} placeholder
     */
    onClickPlaceholder(placeholder) {
        // Nastavime placeholder do clipboardu
        navigator.clipboard.writeText(placeholder);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { template, user } = this.props;
        const { data, loading } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        // Je to edit?
        const isEdit = this.getTemplateId();

        const isView = user.mtokna_moderator || user.mtokna_type === 'mtokna_owner';

        return (
            <div className="mtokna_template">
                <div className="mtokna_template__header">
                    <div className="mtokna_template__header__left">
                        <div className="mtokna_template__header__left__title">
                            {isEdit ? (isView ? data.subject : __('Úprava emailu')) : __('Nový email')}
                        </div>
                    </div>
                </div>
                <div className="mtokna_template__content">
                    {!isView ? <Message type="info">{__('Do emailu môžete vkladať špecialné slova ktoré sa pri odoslaní automaticky doplnia podľa objednávky.')}</Message> : null}
                    <div className="mtokna_template__content__panels">
                        {!isView ? <div className="mtokna_template__content__panels__panel">
                            <div className="mtokna_template__content__panels__panel__title">{__('Špeciálne slová')}</div>
                            <div className="mtokna_template__content__panels__panel__text">{__('Kliknutím skopírujete do schránky.')}</div>
                            <div className="mtokna_template__content__panels__panel__placeholders">
                                {_.map({
                                    '{seller.name}': __('Názov predajcu'),
                                    '{seller.password}': __('Heslo pre vyzdvihnutie'),
                                    '{seller.text}': __('Popis predajcu'),
                                    '{seller.email}': __('Email predajcu'),
                                    '{owner.name}': __('Názov VO'),
                                }, (text, placeholder) => {
                                    return (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                                {_.map({ ...PLACEHOLDERS, ...ADDITIONAL_PLACEHOLDERS, ...EMAIL_PLACEHOLDERS }, (text, placeholder) => {
                                    if (_.includes(['{order.payment_type}', '{order.delivery_type}'], placeholder)) {
                                        // Nechceme zobrazovat
                                        return null;
                                    }

                                    return (
                                        <div key={placeholder}>
                                            <Tooltip title={text}>
                                                <Chip
                                                    onClick={() => this.onClickPlaceholder(placeholder)}
                                                    label={placeholder}
                                                    clickable
                                                    color="primary"
                                                    deleteIcon={<HelpIcon />}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        </div> : null}
                        <div className="mtokna_template__content__panels__panel">
                            {!isView ? <Input
                                label={__('Názov')}
                                value={data.name}
                                onChange={value => this.onChange('name', value)}
                                disabled={isEdit}
                            /> : null}
                            {!isView ? <Input
                                label={__('Predmet')}
                                value={data.subject}
                                onChange={value => this.onChange('subject', value)}
                                placeholder={__('Objednávka {order.number}')}
                            /> : null}
                            <div className="input">
                                {!isView ? <CKEditor
                                    editor={Editor}
                                    data={data.text}
                                    onChange={(event, editor) => this.onChange('text', editor.getData())}
                                /> : <div dangerouslySetInnerHTML={{__html: data.text }} />}
                            </div>
                        </div>
                    </div>
                    {!isView ? <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_template__content__button"
                        color="green"
                    >{__('Uložiť')}</Button> : null}
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ mtokna_template, user }) => ({ template: mtokna_template, user });

export default withCookies(connect(stateToProps, {
    fetchMtoknaTemplate,
    setUser,
    createMtoknaTemplate,
    cleanMtoknaTemplate,
})(MtoknaTemplateScreen));
