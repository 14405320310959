import _ from 'lodash';

/**
 * Je validne zip?
 *
 * @param {string} zip
 *
 * @return {boolean}
 */
export const isValidZip = zip => /^[0-9]{4,6}$/.test(_.toString(zip));
