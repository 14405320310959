import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_MTOKNA_MANUAL_SELLER_ORDERS,
    CLEAN_MTOKNA_MANUAL_SELLER_ORDERS,
    EDIT_MTOKNA_MANUAL_SELLER_ORDER,
    DELETE_MTOKNA_MANUAL_SELLER_ORDER,
    CREATE_MTOKNA_MANUAL_SELLER_ORDER_INVOICE,
} from '../actions/types';

const MTOKNA_MANUAL_SELLER_ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const MtoknaManualSellerOrdersReducer = (state = MTOKNA_MANUAL_SELLER_ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_MTOKNA_MANUAL_SELLER_ORDERS:
            return payload;

        case CLEAN_MTOKNA_MANUAL_SELLER_ORDERS:
        case DELETE_MTOKNA_MANUAL_SELLER_ORDER:
            return MTOKNA_MANUAL_SELLER_ORDERS_DEFAULT_STATE;

        case EDIT_MTOKNA_MANUAL_SELLER_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case CREATE_MTOKNA_MANUAL_SELLER_ORDER_INVOICE:
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku, pridame a nastavime objednavku ako procesovanu
                    const data = payload[toNumber(item.id)];

                    item = _.isString(data)
                        ? { ...item, error_invoice: data }
                        : { ...item,
                            invoice: data,
                            invoice_status_pdf: 'newly',
                            invoice_status: data.paid > 0 ? 'paid' : 'unpaid',
                        };
                }

                return item;
            }) };

        default:
            return state;
    }
};

export default MtoknaManualSellerOrdersReducer;
